import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'

import cn from './index.module.scss'
import { Link } from 'gatsby'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className={cn.container}>
        <div className={cn.left}>
          <h1>
            Freedom
            <br />
            Genetics
            <br />
            LLC
          </h1>
        </div>
        <div className={cn.right}>
          <h3 className={cn.subtitle}>
            Freedom
            <br />
            Through
            <br />
            Genetics
          </h3>
          <Link to="/contact">
            <Button small success>
              Contact Us
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
